<template>
  <div class="countdown">
    <div class="time">{{ timeLeft }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeLeft: '',
      countdownDate: new Date("May 30, 2024 08:30:00").getTime(),
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const distance = this.countdownDate - now;
        
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        this.timeLeft = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        
        if (distance < 0) {
          clearInterval(this.startCountdown);
          this.timeLeft = "On route!!";
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Orbitron', sans-serif;
  color: #fff;
}
.time {
  font-size: 3em;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid #00ffea;
  box-shadow: 0 0 20px #00ffea, 0 0 40px #00ffea, 0 0 60px #00ffea;
  border-radius: 10px;
  text-shadow: 0 0 10px #00ffea, 0 0 20px #00ffea, 0 0 30px #00ffea;
}
</style>
