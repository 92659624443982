<template>
  <div id="app">
    <CountdownTimer />
  </div>
</template>

<script>
import CountdownTimer from './components/CountdownTimer.vue';

export default {
  name: 'App',
  components: {
    CountdownTimer: CountdownTimer
  }
};
</script>

<style>
#app {
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Orbitron', sans-serif;
}
</style>